import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome
import './RecordManager.css'
import logo from './logo.png'; // Import the logo

function App() {
  const [groups, setGroups] = useState({});
  const [email, setEmail] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [allGroups, setAllGroups] = useState([]);

  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = async () => {
    try {
      const response = await axios.get('https://recordmanager.marriedtothemopnewsletter.online/groups');
      //const response = await axios.get('http://localhost:3020/groups');
      console.log('Fetched groups:', response.data); // Debugging line

      if (response.data && response.data.groupedData) {
        setGroups(response.data.groupedData);
        setAllGroups(Object.keys(response.data.groupedData));
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching groups:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://recordmanager.marriedtothemopnewsletter.online/delete/${id}`);
      //await axios.delete(`http://localhost:3020/delete/${id}`);
      fetchGroups(); // Refresh the list after deleting
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://recordmanager.marriedtothemopnewsletter.online/add', { email, group: selectedGroup });
      //await axios.post('http://localhost:3020/add', { email, group: selectedGroup });
      setEmail('');
      setSelectedGroup('');
      fetchGroups(); // Refresh the list after adding
    } catch (error) {
      console.error('Error adding record:', error);
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
    {/* Display the logo */}
    <img src={logo} alt="Logo" style={{ width: '150px', marginTop: '20px' }} />

    <h1>Email Records</h1>

      <table
        border="1"
        cellPadding="10"
        cellSpacing="0"
        style={{ margin: '0 auto', width: '80%' }}
      >
        <thead>
          <tr>
            <th>#</th> {/* Serial Number Header */}
            <th>Group</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
        {allGroups.length > 0 ? (
            allGroups.flatMap((groupName, groupIndex) => {
              let serialNumber = 1; // Start the serial number from 1 and increment across groups
              return groups[groupName].map((record) => (
                <tr key={record.id}>
                  <td>{serialNumber++}</td> {/* Serial Number */}
                  <td>{groupName}</td>
                  <td>{record.email}</td>
                  <td style={{ textAlign: 'center' }}>
                    <i
                      className="fas fa-trash-alt"
                      style={{ color: 'red', cursor: 'pointer' }}
                      onClick={() => handleDelete(record.id)}
                      title="Delete"
                    ></i>
                  </td>
                </tr>
              ));
            })
          ) : (
            <tr>
              <td colSpan="4">No records available.</td>
            </tr>
          )}
        </tbody>
      </table>

      <h2>Add New Record</h2>
      <form onSubmit={handleAdd} style={{ textAlign: 'center' }}>
        <input
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <select
          value={selectedGroup}
          onChange={(e) => setSelectedGroup(e.target.value)}
          required
        >
          <option value="">Select Group</option>
          {allGroups.map(group => (
            <option key={group} value={group}>{group}</option>
          ))}
        </select>
        <button type="submit">Add Record</button>
      </form>
    </div>
  );
}

export default App;
